<template>
  <div>
    <page-header :title="$t('compute.text_91')" :current-tab.sync="cloudEnv">
      <div slot="res-status-tab" style="position: absolute; right: 0; top: 14px;">
        <res-status-tab
          :loading="statisticsLoading"
          :status-opts="statusOpts"
          @click="statusClickHandle" />
      </div>
    </page-header>
    <page-body>
      <vm-instance-list
        :id="listId"
        :cloud-env="cloudEnv"
        :cloudEnvOptions="cloudEnvOptions"
        :filterParams="filterParams"
        :hiddenActions="hiddenActions"
        :tableOverviewIndexs="tableOverviewIndexs"
        @updateCloudEnvOptions="updateCloudEnvOptions" />
    </page-body>
  </div>
</template>

<script>
import { getCloudEnvOptions } from '@/utils/common/hypervisor'
import ResStatisticsMixin from '@/mixins/resStatisticsMixin'
import VmInstanceList from './components/List'

export default {
  name: 'VmInstanceIndex',
  components: {
    VmInstanceList,
  },
  mixins: [ResStatisticsMixin],
  data () {
    return {
      listId: 'VMInstanceList',
      cloudEnv: '',
      resStaticsResource: 'servers',
      hiddenActions: [],
    }
  },
  computed: {
    cloudEnvOptions: () => {
      return getCloudEnvOptions('compute_engine_brands')
    },
  },
  async created () {
    const data = await this.$http.get('/v1/products/product-status?code=ecs')
    if (!data.data) {
      this.hiddenActions.push('create')
    } else {
      this.hiddenActions = []
    }
  },
}
</script>
