<template>
  <page-footer>
    <template v-slot:right>
      <div class="d-flex align-items-center">
        <div class="mr-4 d-flex align-items-center">
          <div class="text-truncate">{{ $t("compute.text_286") }}</div>
          <div class="ml-2 prices">
            <div v-if="originPrice" class="hour d-flex">
              <div>{{ originPrice }}</div>
            </div>
            <div v-else class="tips text-truncate">
              <span v-html="priceTips" />
            </div>
          </div>
        </div>
      </div>
      <div class="btns-wrapper d-flex align-items-center">
        <a-button
          class="ml-3"
          type="primary"
          native-type="submit"
          html-type="submit"
          @click="handleConfirm"
          :loading="loading">{{ $t("common_258") }}</a-button>
      </div>
    </template>
  </page-footer>
</template>

<script>
import _ from 'lodash'
import { mapGetters } from 'vuex'
import { PROVIDER_MAP } from '@/constants'
import { hasMeterService } from '@/utils/auth'
import { numerify } from '@/filters'

export default {
  name: 'BottomBar',
  inject: ['form'],
  props: {
    currentCloudregion: {
      type: Object,
    },
    currentStorage: {
      type: Object,
    },
    storageTypes: {
      type: Array,
    },
    size: {
      type: Number,
    },
    currentCloudzone: {
      type: Object,
    },
    provider: {
      type: [Array, String],
    },
  },
  data () {
    this._getPrice = _.debounce(this._getPrice, 500)
    return {
      loading: false,
      priceObj: null,
      currency: '¥',
      discount: 0,
      priceFormat: null,
      originPrice: null,
      priceTips: '--',
      key: '',
      hasMeterService,
    }
  },
  computed: {
    ...mapGetters(['userInfo']),
  },
  watch: {
    currentStorage: {
      deep: true,
      handler (options) {
        this._getPrice()
      },
    },
    currentCloudregion (value) {
      if (value && value.id) {
        this._getPrice()
      }
    },
    'currentCloudzone.external_id' () {
      this._getPrice()
    },
    key () {
      this._getPrice()
    },
    size () {
      this._getPrice()
    },
  },
  created () {
    this._getPrice()
  },
  methods: {
    priceFormat1 (price, unit) {
      price = numerify(price, '0.0000')

      return `${this.currency} ${price}${unit}`
    },
    async _getPrice () {
      if (!this.currentCloudregion?.id) return
      const params = {
        area_mapping_id: this.currentCloudregion?.id,
        type: this.currentStorage.value,
      }
      new this.$Manager('price_ebss/price', 'v2')
        .list({
          params: params,
        })
        .then((res) => {
          const { size } = this.form.fc.getFieldsValue()
          if (!res.data.volume_price) {
            this.originPrice = null
            return
          }
          this.originPrice = this.priceFormat1(res.data.volume_price * size, '/时')
        })
    },
    doCreate (data) {
      return new this.$Manager('disks').create({ data })
    },
    async handleConfirm () {
      this.loading = true
      try {
        let values = await this.form.fc.validateFields()
        const {
          project,
          domain,
          cloudregion,
          zone,
          manager_id,
          backend,
          encryptEnable,
          encrypt_key_id,
          encrypt_key_alg,
          server,
          host,
          storage,
          ...rest
        } = values
        const oProvider = PROVIDER_MAP[this.currentCloudregion.provider]
        const provider = Array.isArray(this.provider)
          ? this.provider[0]
          : this.provider
        values = {
          ...rest,
          backend: backend.split('__')[0],
          medium: backend.split('__')[1],
          hypervisor: oProvider ? oProvider.hypervisor : provider,
          size: values.size * 1024,
          project_domain:
            (domain && domain.key) || this.userInfo.projectDomainId,
          project_id: (project && project.key) || this.userInfo.projectId,
          // prefer_region_id: cloudregion,
          area_mapping_id: cloudregion,
          // replace_list_key: 'prefer_region_id,prefer_zone',
          // prefer_zone: zone,
          prefer_manager_id: manager_id,
        }
        delete values.replace_list_key
        if (encryptEnable === 'existing' && encrypt_key_id) {
          values.encrypt_key_id = encrypt_key_id
        } else if (encryptEnable === 'new') {
          values.encrypt_key_new = true
          values.encrypt_key_alg = encrypt_key_alg
          values.encrypt_key_user_id = this.userInfo.id
        }
        if (host) {
          values.prefer_host_id = host
        }
        if (storage) {
          values.storage_id = storage
        }
        Reflect.deleteProperty(values, 'cloudregion')
        Reflect.deleteProperty(values, 'zone')
        const { data } = await this.doCreate(values)
        const successBack = () => {
          this.$message.success(this.$t('k8s.text_184'))
          this.$router.push('/disk')
        }
        if (server) {
          let timer = null
          timer = setInterval(async () => {
            const res = await this.fetchDiskInfo(data.id)

            if (res.data.status === 'ready') {
              clearInterval(timer)
              await this.attachServers(data, server)
              successBack()
            } else if (res.data.status.includes('fail')) {
              clearInterval(timer)
              successBack()
            }
          }, 1000)
        } else {
          successBack()
        }
      } catch (error) {
        this.loading = false
        throw error
      }
    },
    getType (curVal) {
      const curIndex = curVal.lastIndexOf('__')
      const type = curVal.substring(0, curIndex)
      const backend = curVal.substr(curIndex + 2)
      return { type, backend }
    },
    async attachServers (disk, server) {
      return new this.$Manager('servers').batchPerformAction({
        action: 'attachdisk',
        ids: [server],
        data: {
          disk_id: disk.id,
        },
      })
    },
    async fetchDiskInfo (id) {
      return new this.$Manager('disks').get({ id })
    },
  },
}
</script>

<style lang="less" scoped>
@import "../../../../../../src/styles/less/theme";

.prices {
  .hour {
    color: @error-color;
    font-size: 24px;
  }
  .tips {
    color: #999;
    font-size: 12px;
  }
}
</style>
