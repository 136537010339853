<template>
  <div>
    <page-header v-if="isAdminMode" :title="$t('compute.text_105')" :current-tab.sync="cloudEnv" />
    <page-header v-else :title="$t('compute.text_105')" :current-tab.sync="cloudEnv" />
    <page-body>
      <secgroup-list :id="listId" :cloudEnv="cloudEnv" />
    </page-body>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { getCloudEnvOptions } from '@/utils/common/hypervisor'
import SecgroupList from './components/List'

export default {
  name: 'SecgroupIndex',
  components: {
    SecgroupList,
  },
  data () {
    return {
      listId: 'SecgroupList',
      cloudEnvOptions: getCloudEnvOptions('security_group_brands'),
      cloudEnv: '',
    }
  },
  computed: {
    ...mapGetters(['isAdminMode']),
  },
}
</script>
